<template>
  <div>
    <b-card>
      <template #header>
        <h5 class="card-title">Tüm Kullanıcılar</h5>
      </template>
      <div class="ml-n2 mr-n2">
        <b-table
          class="position-relative"
          :items="datasource.users.table_items"
          :fields="datasource.users.table_columns"
          responsive
          show-empty
          empty-text="Kullanıcı Bulunmuyor"
        >
          <template #cell(col_user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.value.profile_url"
                  :text="shortUserName(data.value.user_namesurname)"
                  :to="{
                    name: 'user-update',
                    query: { user: data.value.user_uniq },
                  }"
                />
              </template>
              <b-link
                :to="{
                  name: 'user-update',
                  query: { user: data.value.user_uniq },
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.value.user_namesurname }}
              </b-link>
              <small class="text-muted"
                >@{{ data.value.user_namesurname }}</small
              >
            </b-media>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
export default {
  components: {
    vSelect,
    flatPickr,
  },
  data() {
    return {
      datasource: {
        loaded: false,
        users: {
          stack_data: [],
          table_items: [],
          table_columns: [
            { key: "col_user", label: "" },
            { key: "col_email", label: "Email", sortable: true },
            { key: "col_gsm", label: "GSM", sortable: true },
            { key: "col_title", label: "Ünvan" },
            { key: "col_lastaction", label: "Son İşlem" },
          ],
        },
      },
    };
  },
  created() {
    if (!utils.userHasRole("list_user")) {
      this.$router.push("/not-found");
      return;
    }
    utils.setTitle("Kullanıcılar");
    this.getUsers();
  },
  methods: {
    getUsers() {
      api.action("getUsers").then((q) => {
        if (q.success) {
          this.datasource.users.stack_data = q.data;
          q.data.forEach((user) => {
            let item = {
              col_user: user,
              col_email: user.user_email_primary,
              col_gsm: user.user_gsm_primary,
              col_title: utils.getDefinition(user.title_id, "name"),
              col_lastaction:
                user.last_action != null
                  ? JSON.stringify(user.last_action)
                  : "",
            };
            this.datasource.users.table_items.push(item);
          });
        } else {
          utils.notificationToast("warning", "Bilgi", q.message);
        }
      });
    },
    moment: function () {
      return moment();
    },
    userHasRole(rolename) {
      return utils.userHasRole(rolename);
    },
    shortUserName(username) {
      if (username == null) return;
      let result = "";
      if (username.includes(" ")) {
        result = username.split(" ")[0][0] + "" + username.split(" ")[1][0];
      } else {
        result = username[0];
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>